/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { GR, GB } from 'country-flag-icons/react/3x2';
import DarkModeToggle from 'react-dark-mode-toggle';

import {
  toggleDarkMode,
  toggleLanguageMode,
  uiStore,
} from '../../features/uiSlice';

const Navbar = () => {
  const rootDoc = document.querySelector(':root');
  const { darkMode, language } = useSelector(uiStore);
  const dispatch = useDispatch();

  // Dark mode toggle
  const handleDarkMode = () => {
    dispatch(toggleDarkMode());
  };
  const handleLanguageMode = () => {
    dispatch(toggleLanguageMode());
  };

  // Store darkmode value to localStorage;
  useEffect(() => {
    if (darkMode) rootDoc.classList.add('dark');
    else rootDoc.classList.remove('dark');
    localStorage.setItem('omodos-theme-mode', JSON.stringify(darkMode));
  }, [darkMode]);

  useEffect(() => {
    if (language) rootDoc.classList.add('english');
    else rootDoc.classList.remove('english');
    localStorage.setItem('omodos-language-mode', JSON.stringify(language));
  }, [language]);

  return (
    <div className='navbar  w-full z-20 top-0 left-0 px-[2%]  md:px-[6%] flex-center-between py-[0.35rem] bg-white/60 border-b backdrop-blur-sm dark:border-dark dark:bg-card-dark/60'>
      <Link to='/' className='flex-shrink-0 flex-align-center gap-x-1'>
        <div className='flex-align-center'>
          <img src='/modoino.png' alt='ModΟινώ Guest Houses' />
          <h1 className='flex text-lg ml-2'>ModΟινώ Guest Houses</h1>
        </div>
      </Link>

      <div className='flex-align-center gap-x-4'>
        <div className='space-x-2 flex-align-center'>
          {/*----------------------------- Dark mode toggle-------------------------------------------------- */}

          <div
            className=' hover:shadow-lg hover:bg-transparent'
            onClick={handleLanguageMode}
          >
            {language ? (
              <GR height={25} width={25} />
            ) : (
              <GB height={25} width={25} />
            )}
          </div>

          <DarkModeToggle
            onChange={handleDarkMode}
            checked={darkMode}
            size={50}
          />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
