import { BiBed } from 'react-icons/bi';
import { TbSofa } from 'react-icons/tb';
import { BsPeople } from 'react-icons/bs';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { HiOutlinePhone } from 'react-icons/hi';
import { FaBaby } from 'react-icons/fa';
import ImageGallery from 'react-image-gallery';
import { useSelector } from 'react-redux';
import { uiStore } from '../../../features/uiSlice';

const SingleProductCard = ({
  name,
  name_gr,
  number_of_beds,
  number_of_sofa_beds,
  number_of_guests,
  description1,
  description2,
  description3,
  description4,
  description1_gr,
  description2_gr,
  description3_gr,
  description4_gr,
  bookLink,
  basis,
  images,
  onOpenModal,
}) => {
  const { language } = useSelector(uiStore);

  return (
    <div
      className={`flex-1 ${
        basis ? basis : 'basis-[18rem]'
      } shadow-light dark:border-card-dark border rounded-lg overflow-hidden relative group`}
    >
      <ImageGallery
        items={images}
        lazyLoad={true}
        useBrowserFullscreen={false}
      />
      <div className='p-3 '>
        <div className='text-primary'>
          <h1 className='text-lg font-bold capitalize'>
            {language ? name : name_gr}
          </h1>
        </div>
        <div
          onClick={onOpenModal}
          className='group-hover:text-primary transition-a flex-align-center mt-3'
        >
          <h1 className='text-lg font-bold capitalize mr-3'>
            {language ? 'Location' : 'Τοποθεσία'}
          </h1>
          <FaMapMarkerAlt>Location</FaMapMarkerAlt>
        </div>
        <div className='flex-direction-row gap-3 mt-3'>
          <h1>{language ? description1 : description1_gr}</h1>
          <h1>{language ? description2 : description2_gr}</h1>
          <h1>{language ? description3 : description3_gr}</h1>
          {description4 !== '' ? (
            <h1>{language ? description4 : description4_gr}</h1>
          ) : (
            <br />
          )}
        </div>
        <div className='flex gap-3 mt-3'>
          <div className='flex-align-center gap-x-3'>
            <div className='icon-box !w-7 !h-7 bg-primary/20 hover:!bg-primary/40 text-primary'>
              <BiBed />
            </div>
            <p className='text-sm'>
              {number_of_beds} {language ? 'Bed' : 'Κρεβάτι'}
            </p>
          </div>
          <div
            className={
              language
                ? 'flex-align-center gap-x-3 ml-7 '
                : 'flex-align-center gap-x-3 '
            }
          >
            <div className='icon-box !w-7 !h-7 bg-primary/20 hover:!bg-primary/40 text-primary'>
              <TbSofa />
            </div>
            <p className='text-sm'>
              {number_of_sofa_beds} {language ? 'Sofa Bed' : 'Καναπές Κρεβάτι'}
            </p>
          </div>
        </div>
        <div className='flex gap-3 mt-3'>
          <div className='flex-align-center gap-x-3'>
            <div className='icon-box !w-7 !h-7 bg-primary/20 hover:!bg-primary/40 text-primary'>
              <BsPeople />
            </div>
            <p className='text-sm'>
              {number_of_guests} {language ? 'Guests' : 'Ατομα'}
            </p>
          </div>
          <div
            className={
              language
                ? 'flex-align-center gap-x-3'
                : 'flex-align-center gap-x-3 '
            }
          >
            <div className='icon-box !w-7 !h-7 bg-primary/20 hover:!bg-primary/40 text-primary ml-3'>
              <FaBaby />
            </div>
            <p className='text-sm'>
              {' '}
              {language ? 'Baby Cot (optional)' : 'Κούνια μωρού (Προαιρετικά)'}
            </p>
          </div>
        </div>
        <div className='mt-10 flex-align-center'>
          <button
            className='btn btn-primary '
            onClick={() => window.open(bookLink, '_blank')}
          >
            {language ? 'BOOK NOW' : 'ΚΡΑΤΗΣΗ'}
          </button>

          <div className='ml-4 flex-align-center'>
            <div className='icon-box !w-8 !h-8 bg-primary/20 hover:!bg-primary/40 text-primary'>
              <HiOutlinePhone />
            </div>

            <a href='tel: +35799895953' className='text-lg ml-1 text-primary'>
              {' '}
              +35799895953{' '}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleProductCard;
