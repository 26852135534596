import { createSlice } from '@reduxjs/toolkit';

const mode = JSON.parse(localStorage.getItem('omodos-theme-mode')) || false;
const language =
  JSON.parse(localStorage.getItem('omodos-language-mode')) || true;

const initialState = {
  darkMode: mode,
  language: language,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleDarkMode: (state) => {
      state.darkMode = !state.darkMode;
    },
    toggleLanguageMode: (state) => {
      state.language = !state.language;
    },
  },
});

export default uiSlice.reducer;

export const uiStore = (state) => state.ui;

export const { toggleDarkMode, toggleLanguageMode } = uiSlice.actions;
