import { useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import { property } from '../../../data/dummyData';
import SingleProductCard from './SingleProductCard';
import { useSelector } from 'react-redux';
import { uiStore } from '../../../features/uiSlice';

const Featured = () => {
  const { language } = useSelector(uiStore);
  ///  <div className='flex flex-wrap gap-14 mt-8'>
  const [open, setOpen] = useState(false);
  const { innerWidth, innerHeight } = window;
  const modalWidth = innerWidth > 600 ? 600 : (innerWidth * 80) / 100;
  const modalHeight = innerHeight > 600 ? 450 : (innerHeight * 80) / 100;
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  return (
    <div className='pt-5 pb-10'>
      <div className='text-center'>
        <h1 className='mx-auto sub-heading '>
          {language ? 'Our Properties' : 'ΚΑΤΟΙΚΙΕΣ'}
        </h1>
      </div>
      <div className='flex flex-wrap gap-14 mt-8'>
        {property.slice(0, 3).map((featured) => (
          <SingleProductCard
            key={featured.id}
            onOpenModal={onOpenModal}
            {...featured}
          />
        ))}
      </div>
      <div>
        <Modal open={open} onClose={onCloseModal} center>
          <iframe
            title='map'
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3274.3582779673598!2d32.810396100000006!3d34.847223899999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14e739e1a609d601%3A0x9b2a7af725b8cae9!2sModoino%20Guest%20House!5e0!3m2!1sel!2s!4v1711607484938!5m2!1sel!2s'
            width={modalWidth}
            height={modalHeight}
            style={{ border: 0 }}
            allowFullScreen=''
            aria-hidden='false'
            tabIndex='0'
          />
        </Modal>
      </div>
    </div>
  );
};

export default Featured;
