import { Routes, Route } from 'react-router-dom';

import Footer from './components/common/Footer';
import Navbar from './components/common/Navbar';
import { PageNotFound, Home } from './pages';
function App() {
  return (
    <div>
      <Navbar />
      <div className='pb-40'>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </div>
      <div className='px-[2%] md:px-[6%] bg-card-dark border border-card-dark'>
        <div className='mt-10'>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default App;
