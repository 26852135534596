import { Featured } from '../components/common/page-componets';

const Home = () => {
  return (
    <div className='pt-16 px-[3%] md:px-[6%]'>
      <Featured />
    </div>
  );
};

export default Home;
