/* eslint-disable jsx-a11y/anchor-is-valid */
import { SocialIcon } from 'react-social-icons';
import { HiOutlineMailOpen, HiOutlinePhone } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import { uiStore } from '../../features/uiSlice';

const Footer = () => {
  const { language } = useSelector(uiStore);
  return (
    <div className='text-slate-200'>
      <footer>
        <div className='flex flex-wrap gap-2'>
          <div className='flex-1 basis-[10rem]'>
            <div className='flex-align-center'>
              <img src='/modoino.png' alt='ModΟινώ Guest Houses' />
              <h1 className='flex ml-2'>ModΟινώ Guest Houses</h1>
            </div>
            <div className='mt-3'>
              <h1 className='text-lg font-bold capitalize'>
                {language ? 'Info' : 'Πληροφορίες'}
              </h1>
              <div className='flex-align-center'>
                <HiOutlineMailOpen />
                <h1 className='font-bold capitalize mr-3 ml-3'>
                  {language ? 'Email: ' : 'Ηλ. Διέυθυνση: '}
                </h1>
                <a
                  href={`mailto:${'omodosrental@gmail.com'}?subject='Customer Question'`}
                  className='text-lg'
                >
                  omodosrental@gmail.com
                </a>
              </div>
              <div className='flex-align-center'>
                <HiOutlinePhone />
                <h1 className='font-bold capitalize mr-3 ml-3'>
                  {language ? 'Phone: ' : 'Τηλέφωνο: '}
                </h1>
                <a href='tel: +35799895953' className='text-lg '>
                  {' '}
                  +35799895953{' '}
                </a>
              </div>
              <div className='gap-5 my-6 flex-center-center'>
                <h1 className='text-md font-bold'>
                  {language ? 'Follow us' : 'Ακολουθήστε μας'}
                </h1>
                <SocialIcon
                  url='https://www.facebook.com/modoino'
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{ height: 35, width: 35 }}
                />

                <SocialIcon
                  url='https://www.instagram.com/modoino_guest_houses/?igsh=MTZlbTZpd2o2ZmZnaw%3D%3D'
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{ height: 35, width: 35 }}
                />
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
