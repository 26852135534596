import { FaFacebook, FaInstagram } from 'react-icons/fa';

export const property = [
  {
    id: 1,
    name: 'Anogeio',
    name_gr: 'Ανώγειο',
    location: 'Address 1',
    price: '€100 per night',
    purpose: 'rent',
    number_of_beds: 1,
    number_of_sofa_beds: 1,
    number_of_guests: 4,
    contact: '+123456',
    dimensions: '3000 sq ft',
    description1:
      'Discover ModΟινώ guesthouse in the picturesque village of Omodos.',
    description2:
      'The combination of traditional and modern elements will impel you to relax and rest.',
    description3:
      'You can enjoy outdoor dining in the patio with a glass of local wine. ',
    description4:
      'Live unforgettable moments and create memories with your loved ones.',
    description1_gr: 'Ανακαλύψτε τον ξενώνα ModΟινώ στο γραφικό Όμοδος!',
    description2_gr:
      'Ο συνδυασμός του παραδοσιακού και του συγχρόνου στοιχείου θα σας παρασύρουν να χαλαρώσετε και να ξεκουραστείτε.  ',
    description3_gr:
      'Μπορείτε να απολαύσετε στην πλακόστρωτη αυλή το υπαίθριο δείπνο σας με ένα ποτήρι ντόπιο κρασί. ',
    description4_gr:
      'Ζήστε αξέχαστες στιγμές και δημιουργήστε αναμνήσεις με τα αγαπημένα σας πρόσωπα.',
    image: '/images/property (25).jpg',
    bookLink:
      'https://www.airbnb.com/rooms/1118859343794443857?source_impression_id=p3_1711298493_sR1vefMkqthp7IpF',
    images: [
      {
        original: '/images/House1/1.jpg',
        thumbnail: '/images/House1/1.jpg',
        loading: 'lazy',
      },
      {
        original: '/images/House1/2.jpg',
        thumbnail: '/images/House1/2.jpg',
        loading: 'lazy',
      },
      {
        original: '/images/House1/3.jpg',
        thumbnail: '/images/House1/3.jpg',
        loading: 'lazy',
      },
      {
        original: '/images/House1/4.jpg',
        thumbnail: '/images/House1/4.jpg',
        loading: 'lazy',
      },
      {
        original: '/images/House1/5.jpg',
        thumbnail: '/images/House1/5.jpg',
        loading: 'lazy',
      },
      {
        original: '/images/House1/6.jpg',
        thumbnail: '/images/House1/6.jpg',
        loading: 'lazy',
      },
      {
        original: '/images/House1/7.jpg',
        thumbnail: '/images/House1/7.jpg',
        loading: 'lazy',
      },
      {
        original: '/images/House1/8.jpg',
        thumbnail: '/images/House1/8.jpg',
        loading: 'lazy',
      },
      {
        original: '/images/House1/9.jpg',
        thumbnail: '/images/House1/9.jpg',
        loading: 'lazy',
      },
      {
        original: '/images/House1/10.jpg',
        thumbnail: '/images/House1/10.jpg',
        loading: 'lazy',
      },
      {
        original: '/images/House1/11.jpg',
        thumbnail: '/images/House1/11.jpg',
        loading: 'lazy',
      },
      {
        original: '/images/House1/12.jpg',
        thumbnail: '/images/House1/12.jpg',
        loading: 'lazy',
      },
      {
        original: '/images/House1/13.jpg',
        thumbnail: '/images/House1/13.jpg',
        loading: 'lazy',
      },
      {
        original: '/images/House1/14.jpg',
        thumbnail: '/images/House1/14.jpg',
        loading: 'lazy',
      },
      {
        original: '/images/House1/15.jpg',
        thumbnail: '/images/House1/15.jpg',
        loading: 'lazy',
      },
    ],
  },
  {
    id: 2,
    name: 'Isogeio',
    name_gr: 'Ισόγειο ',
    location: 'Address 2',
    price: '€150 per night',
    purpose: 'rent',
    number_of_beds: 1,
    number_of_sofa_beds: 1,
    number_of_guests: 4,
    contact: '+12155555',
    dimensions: '2000 sq ft',
    description1:
      'Discover ModΟινώ guesthouse in the picturesque village of Omodos.',
    description2:
      'The combination of traditional and modern elements will impel you to relax and rest.',
    description3:
      'Live unforgettable moments and create memories with your loved ones. ',
    description4: '',
    description1_gr: 'Ανακαλύψτε τον ξενώνα ModΟινώ στο γραφικό Όμοδος!',
    description2_gr:
      'Ο συνδυασμός του παραδοσιακού και του συγχρόνου στοιχείου θα σας παρασύρουν να χαλαρώσετε και να ξεκουραστείτε.  ',
    description3_gr:
      'Ζήστε αξέχαστες στιγμές και δημιουργήστε αναμνήσεις με τα αγαπημένα σας πρόσωπα.',
    description4_gr: '',
    image: '/images/property (26).jpg',
    bookLink:
      'https://www.airbnb.com/rooms/1119711643136691926?source_impression_id=p3_1721972335_P3UkBn9S5AOxmnSo',
    images: [
      {
        original: '/images/House2/1.jpg',
        thumbnail: '/images/House2/1.jpg',
        loading: 'lazy',
      },
      {
        original: '/images/House2/2.jpg',
        thumbnail: '/images/House2/2.jpg',
        loading: 'lazy',
      },
      {
        original: '/images/House2/3.jpg',
        thumbnail: '/images/House2/3.jpg',
        loading: 'lazy',
      },
      {
        original: '/images/House2/4.jpg',
        thumbnail: '/images/House2/4.jpg',
        loading: 'lazy',
      },
      {
        original: '/images/House2/5.jpg',
        thumbnail: '/images/House2/5.jpg',
        loading: 'lazy',
      },
      {
        original: '/images/House2/6.jpg',
        thumbnail: '/images/House2/6.jpg',
        loading: 'lazy',
      },
      {
        original: '/images/House2/7.jpg',
        thumbnail: '/images/House2/7.jpg',
        loading: 'lazy',
      },
      {
        original: '/images/House2/8.jpg',
        thumbnail: '/images/House2/8.jpg',
        loading: 'lazy',
      },
      {
        original: '/images/House2/9.jpg',
        thumbnail: '/images/House2/9.jpg',
        loading: 'lazy',
      },
      {
        original: '/images/House2/10.jpg',
        thumbnail: '/images/House2/10.jpg',
        loading: 'lazy',
      },
    ],
  },
];

export const socials = [<FaFacebook />, <FaInstagram />];
